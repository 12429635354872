.mainBtn {
  background: #ffc500;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  height: 40px;
  border: none;
}
.mainBtn:hover {
  cursor: pointer;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.menuToggleCls .MuiSvgIcon-root {
  background-color: red !important;
}

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10000;
}
.loaderWrapper .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loaderWrapper .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ffc500;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffc500 transparent transparent transparent;
}
.loaderWrapper .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loaderWrapper .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loaderWrapper .lds-ring div :nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.measureListStyle {
  max-width: 140px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.lblStyle {
  font-size: 12px;
}

.reporttable {
  font-size: x-small;
  width: 100%;
}
.reporttableBottom {
  width: 100%;
  text-align: left;
  font-weight: normal;
}

.reporttableBottom tbody tr {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
}

.reporttableBottom tfoot tr {
  vertical-align: bottom;
  border-top: 1px solid #dee2e6;
  text-align: left;
}

.reporttableBottom tbody td {
  border-bottom: 1px solid #ccc;
  font-size: "x-small" !important;
  width: 30px;
  text-align: left;

}

.reporttable tbody > tr:last-child {
  border-bottom: 0;
  
}

.table-title {
  font-weight: bold;
  font-size: small;
  text-align: center;
}

.reporttable thead tr {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
  text-align: right;
}

.reporttable thead td {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
  text-align: right;
}

.reporttable tbody tr {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  font-weight: normal;
  height: 17px;
  text-align: right;
}

.reporttable tfoot tr {
  vertical-align: bottom;
  border-top: 1px solid #dee2e6;
  font-weight: bold;
  text-align: right;
}

.reporttable tbody td {
  border-bottom: 1px solid #ccc;
  width: 30px;
  text-align: right;

}

.row {
  height: 100%;
}

.container-fluid {
  height: 100%;
}

.roundborder {
  border: 1px solid gray;
  border-radius: 4px;
}

#map {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tbldiv {
  margin-bottom: 2px;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 227px;
  margin-right: 1px;
  margin-bottom: 3px;
  padding: 10px;
  height: 116px; 
  border: 1px solid gray;
  border-radius: 4px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0px !important;
}

.errorCls {
  color: #ff6969;
  font-size: 12px;
  display: flex;
}

.googleButtonCls {
  font-family: roboto;
  font-weight: 700 !important;
}

.signupBtnCls {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 4px 10px;
}
.signupBtnCls:hover {
  background-color: transparent;
}

.formStyle {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.inputStyle {
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #dddddd;
  width: 90%;
}/*# sourceMappingURL=style.css.map */

.progressDiv {
  /* bottom: -68px; */
  width: 98%;
  margin-left: 2px;
  margin-top: 2px;
  padding: 2px;
  /* height: 17%; */
}

.legendDiv {
  width: 260px;
  margin-left: 2px;
  padding: 5px;
}


.infoDiv { 

  position: absolute;
  bottom: 10px;
  width: 98%;
}

.legendReportDiv {
  position: absolute;
  bottom: 0;
  width: 271px;
}